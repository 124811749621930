import { createBrowserRouter } from 'react-router'

import Layout from '@/components/layout/Layout'
import Home from '@/pages/home/index'
import Main from '@/pages/main/index'
import NotFound from '@/pages/not-found/index'
import Login from '@/pages/login/index'
import SignUp from '@/pages/sign-up'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/main',
    element: <Main />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/sign-up',
    element: <SignUp />,
  },
  {
    path: '*',
    element: (
      <Layout>
        <NotFound />
      </Layout>
    ),
  },
])

export default router
