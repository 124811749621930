import React, { useState } from 'react'
import { CircleCheckBigIcon } from 'lucide-react'
import { useMedia } from 'react-use'

import LandingSection from './landing-section'
import BlurFade from '../blur-fade'

const LandingListItem = ({ title }: { title: string }) => {
  return (
    <li className="space-y-2">
      <div className="flex space-x-2">
        <div>
          <CircleCheckBigIcon size={20} color="white" fill="#0c66ee" />
        </div>
        <div>{title}</div>
      </div>
    </li>
  )
}

const LandingFeature = ({
  titleFirst,
  titleSecond,
  subTitle,
  lists,
  image,
  left,
}: {
  titleFirst: string
  titleSecond: string
  subTitle: string
  lists: string[]
  image: string
  left: boolean
}) => {
  const isWide = useMedia('(min-width: 1024px)')
  const imageItem = (
    <div className="col-span-12 lg:col-span-7">
      <div className="w-full">
        <BlurFade delay={0.25} duration={0.7} inView>
          <img src={image} className="w-[95%]" alt="" />
        </BlurFade>
      </div>
    </div>
  )
  const isLeft = left && isWide
  return (
    <section className="w-full my-12">
      <LandingSection>
        {isLeft ? imageItem : ''}
        <div className="col-span-12 px-4 mt-20 space-y-6 lg:col-span-5 sm:px-6">
          <BlurFade delay={0.25} duration={0.7} inView>
            <h2 className="text-3xl font-semibold">
              {titleFirst} <span className="text-[#226CFF]">{titleSecond}</span>
            </h2>
            <p className="paragraph">{subTitle}</p>
          </BlurFade>
          <ul className="space-y-4 sm:space-y-2">
            {lists.map((item, index) => (
              <BlurFade
                key={index}
                delay={0.25 + index * 0.1}
                duration={0.7}
                inView
              >
                <LandingListItem title={item} />
              </BlurFade>
            ))}
          </ul>
        </div>
        {isLeft ? '' : imageItem}
      </LandingSection>
    </section>
  )
}

export default LandingFeature
