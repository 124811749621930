import { get, getToken, useDelete, usePost } from '@/lib/request'

export async function queryCurrentUser(): Promise<API.UserInfo | undefined> {
  let response: API.RestResult<API.UserInfo> =
    await get<API.UserInfo>('/api/user/info')
  return response?.data
}

export function useLogout(option?: API.UseParam) {
  return useDelete('/api/user/logout', option)
}

export function useUsernameLogin(option?: API.UseParam) {
  return usePost('/api/user/login', option)
}

export function isUserLogin() {
  const token = getToken()
  return !!token
}
