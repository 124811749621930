import React from 'react'
import { RouterProvider } from 'react-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Toaster } from '@/components/ui/toaster'

import ErrorBoundary from './components/error-boundary'
import router from './router'

const queryClient = new QueryClient()

const App: React.FC = () => (
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
    </QueryClientProvider>
  </ErrorBoundary>
)
App.displayName = 'App'

export default App
