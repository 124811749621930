import type { PropsWithChildren } from 'react'
import React from 'react'
import clsx from 'clsx'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean
}

const LandingButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <button
        className={clsx(
          className,
          'text-sm text-center rounded-full hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300',
        )}
        {...props}
      >
        {children}
      </button>
    )
  },
)

export default LandingButton
