import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { BarChart3, DollarSign, Radio, TrendingUp } from 'lucide-react'
import BlurFade from '../blur-fade'

interface Testimonial {
  icon: React.ReactNode
  category: string
  content: string
  author: {
    name: string
    role: string
    company: string
  }
}

const testimonials: Testimonial[] = [
  {
    icon: <BarChart3 className="w-6 h-6 text-blue-500" />,
    category: 'Software Engineering',
    content:
      'This software was a game-changer for my interview prep.the AI-driven practice questions were spot on,and the frrdback helped me improve quickly.I felt confident going into my interviews and ended up landing my dream job.Highly recommend!',
    author: {
      name: 'Lucas',
      role: 'Software Engineer',
      company: 'Lyft',
    },
  },
  {
    icon: <DollarSign className="w-6 h-6 text-blue-500" />,
    category: 'Finance',
    content:
      "As someone in finance,I found this software invaluable for interview prep. The tailored questions helped me stay sharp and focused. Thanks to this tool,I aced my interviews and secured a fantastic position. It's a must-have for finance professionals!",
    author: {
      name: 'Amy',
      role: 'Quantitative Researcher',
      company: 'Citadel',
    },
  },
  {
    icon: <Radio className="w-6 h-6 text-blue-500" />,
    category: 'Entertainment',
    content:
      'This software was ideal for my entertainment industry interviews.The tailored questions and feedback helped me refine my responses and stand out. I felt well-prepared and landed a great role. Highly recommend!',
    author: {
      name: 'Lily',
      role: 'Account Executive',
      company: 'Salesforce',
    },
  },
  {
    icon: <TrendingUp className="w-6 h-6 text-blue-500" />,
    category: 'Investment',
    content:
      '"AI won\'t take your job, but someone using AI might." As a banker, I found this AI-powered Interview Copilot to be invaluable. The personalized questions and real-time suggestion gave me the competitive edge to secure a top position. A must-have for investment professionals!',
    author: {
      name: 'Richard',
      role: 'Banker',
      company: 'JP Morgan',
    },
  },
]

export default function LandingTestimonial() {
  return (
    <section className="px-4 py-12 mx-auto md:px-6 lg:px-8 max-w-7xl">
      <div className="mb-12 text-center">
        <BlurFade delay={0.25} duration={0.7} inView>
          <h2 className="text-3xl font-bold tracking-tight md:text-4xl">
            The real stories that showcase{' '}
            <span className="text-blue-500">the incredible power</span> of our
            product.
          </h2>
        </BlurFade>
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
        {testimonials.map((testimonial, index) => (
          <BlurFade
            key={index}
            delay={0.25 + index * 0.2}
            duration={0.7}
            inView
          >
            <Card className="flex flex-col h-full border-0 shadow-lg">
              <CardHeader>
                <div className="flex items-center gap-2">
                  {testimonial.icon}
                  <CardTitle className="text-lg font-semibold">
                    {testimonial.category}
                  </CardTitle>
                </div>
              </CardHeader>
              <CardContent className="flex flex-col justify-between flex-1">
                <p className="text-sm leading-relaxed text-gray-600">
                  {testimonial.content}
                </p>
                <div className="mt-4 space-y-1">
                  <div className="font-medium text-blue-500">
                    {testimonial.author.name}
                  </div>
                  <div className="text-sm text-gray-500">
                    {testimonial.author.role} @{testimonial.author.company}
                  </div>
                </div>
              </CardContent>
            </Card>
          </BlurFade>
        ))}
      </div>
    </section>
  )
}
