import React from 'react'
import LandingButton from './landing-button'
import { NavLink } from 'react-router'

import logo from '@/assets/logo.svg'

const LandingNavbar = () => {
  return (
    <nav id="navbar" className="relative z-10 w-full text-neutral-800">
      <div className="flex flex-col max-w-screen-xl px-8 py-4 mx-auto lg:items-center lg:justify-between lg:flex-row">
        <div className="flex flex-col items-center space-x-4 lg:flex-row xl:space-x-8">
          <div className="flex flex-row items-center justify-between w-full py-6">
            <div>
              <img src={logo} className="w-24 xl:w-28" alt="Logo" />
            </div>
          </div>
        </div>
        <div className="space-x-3">
          <LandingButton className="px-8 xl:px-10 py-3 mt-2 bg-inherit text-gradient border border-[#0c66ee]">
            <NavLink to={'/login'}>Login</NavLink>
          </LandingButton>
          <LandingButton className="px-8 xl:px-10 py-3 mt-2 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] text-white">
            <NavLink to={'/sign-up'}>Sign Up</NavLink>
          </LandingButton>
        </div>
      </div>
    </nav>
  )
}

export default LandingNavbar
