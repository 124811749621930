import React from 'react'
import LandingButton from './landing-button'

import landingHero from '@/assets/landing/landing_hero.png'
import BlurFade from '../blur-fade'

const LandingHero = () => {
  return (
    <section className="w-full pb-12">
      <div className="relative grid max-w-screen-xl grid-cols-12 px-4 mx-auto overflow-hidden sm:px-8 gap-x-6">
        <div className="col-span-12 px-6 mt-12 lg:col-span-6 xl:mt-10">
          <div className="flex flex-col lg:h-full">
            <BlurFade delay={0.25} duration={0.7} inView>
              <div className="space-y-4 text-center sm:space-y-6 sm:text-left">
                <h1 className="text-[2.5rem] sm:text-3xl xl:text4xl font-bold leading-tight capitalize sm:pr-8 xl:pr-10">
                  Ace Your Next Interview{' '}
                  <h1 className="text-[#226CFF] mt-3">
                    with Al and Community Support
                  </h1>
                </h1>
                <p className="hidden paragraph sm:block text-[#8B97A9] pt-6">
                  DipOffer, the Uitimate interview Copilot
                </p>
              </div>
            </BlurFade>
            <BlurFade
              className="flex flex-col mt-4 mb-10 space-y-4 lg:mt-auto sm:flex-row sm:space-y-0 sm:space-x-4"
              delay={0.25}
              duration={1}
              inView
            >
              <LandingButton className="text-sm text-center rounded-full hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300 max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                Get Started
              </LandingButton>
              <LandingButton className="text-sm text-center rounded-full hover:shadow-md hover:shadow-[#0c66ee]/50 transition duration-300 max-w-full px-6 py-4 bg-inherit text-gradient border border-[#0c66ee] flex items-center justify-center">
                <span>Download App</span>
              </LandingButton>
            </BlurFade>
          </div>
        </div>

        <div className="hidden col-span-12 sm:block lg:col-span-6">
          <div className="w-full">
            <BlurFade delay={0.25} duration={0.7} inView>
              <img src={landingHero} />
            </BlurFade>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingHero
