import React, { memo } from 'react'

import LandingNavbar from '@/components/landing/landing-navbar'
import LandingFooter from '@/components/landing/landing-footer'
import LandingHero from '@/components/landing/landing-hero'
import LandingFeature from '@/components/landing/landing-feature'
import LandingFeatureStep from '@/components/landing/landing-feature-step'
import LandingTestimonial from '@/components/landing/landing-testimonial'
import LandingFAQ from '@/components/landing/landing-faq'

import landingFeatUser from '@/assets/landing/landing_feat_user.png'
import landingFeatInterview from '@/assets/landing/landing_feat_interview.png'
import landingFeatInterface from '@/assets/landing/landing_feat_interface.png'
import landingFeatKnowledge from '@/assets/landing/landing_feat_knowledge.png'
import BlurFade from '@/components/blur-fade'

const Home: React.FC = memo(() => (
  <div className="relative min-h-screen font-sans antialiased">
    <div className="relative">
      <section className="bg-[#F9FAFF]">
        <div className="relative">
          <LandingNavbar />
          <LandingHero />
        </div>
      </section>

      <BlurFade delay={0.25} duration={0.7} inView>
        <h1 className="pt-10 text-3xl font-semibold leading-tight text-center md:whitespace-nowra">
          With DipOffer's help,{' '}
          <span className="text-[#226CFF]">
            even the toughest interview becomes manageable.
          </span>
        </h1>
      </BlurFade>

      <LandingFeature
        titleFirst="Wide Range of"
        titleSecond="Users"
        subTitle="Suitable for All Levels:"
        lists={[
          'From senior managers to fresh graduates.',
          'Useful for all professions and levels, software engineers, product managers, data scientists, financial analysts etc.',
        ]}
        image={landingFeatUser}
        left={false}
      />

      <section className="bg-[#F9FAFF]">
        <LandingFeature
          titleFirst="Support All"
          titleSecond="Interviews"
          subTitle="Versatile Interview Support:"
          lists={[
            'Personal experience and background deep dive.',
            'Behavioral questions.',
            'Coding interviews.',
            'System design.',
            'Machine learning algorithms.',
            'All technical questions.',
          ]}
          image={landingFeatInterview}
          left={true}
        />
      </section>

      <LandingFeature
        titleFirst="User Friendly"
        titleSecond="Interface"
        subTitle="Intuitive Interface:"
        lists={[
          'The Copilot works quietly in the background, always prepared to help you.',
          'Capture the screen and conversation to fully understand the process.',
          'Present the real-time guidance in an accessible manner.',
        ]}
        image={landingFeatInterface}
        left={false}
      />

      <section className="bg-[#F9FAFF]">
        <LandingFeature
          titleFirst="Self-evolving"
          titleSecond="Knowledge"
          subTitle="Adaptive Learning:"
          lists={[
            'Powerful Al-driven model combined with retrieval augmented generation.',
            'The more you use it,the better it understands our needs,improved interview performance over time.',
          ]}
          image={landingFeatKnowledge}
          left={true}
        />
      </section>
      <LandingFeatureStep />
      <LandingTestimonial />
      <LandingFAQ />
      <LandingFooter />
    </div>
  </div>
))

export default Home
