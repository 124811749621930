import type { PropsWithChildren } from 'react'
import React from 'react'

import styles from './Layout.module.css'
import LandingNavbar from '../landing/landing-navbar'
import LandingFooter from '../landing/landing-footer'

const Layout: React.FC<PropsWithChildren> = ({ children, ...rest }) => {
  return (
    <div className="relative min-h-screen font-sans antialiased">
      <div className="relative">
        <div className="absolute top-0 left-0 w-full h-[125vh] sm:h-[225vh] lg:h-[125vh] cover-gradient-2 sm:cover-gradient"></div>
        <LandingNavbar />

        <main className={styles.layout} {...rest}>
          <div className={styles.container}>{children}</div>
        </main>

        <LandingFooter />
      </div>
    </div>
  )
}

export default Layout
