import React from 'react'
import { ArrowRightIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'

import logo from '@/assets/logo.svg'

const NavLink = ({ name, url }: { name: string; url: string }) => {
  return (
    <li className="w-full">
      <a
        className="md:px-4 py-2 text-sm bg-transparent rounded-lg text-[#666666] hover:text-gray-900 focus:outline-none focus:shadow-outline"
        href={url}
      >
        {name}
      </a>
    </li>
  )
}

const LandingFooter = () => {
  return (
    <footer className="max-w-screen-xl px-8 mx-auto">
      <div className="w-full border-y border-[#DDDDDD]">
        <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
          <div className="md:w-full lg:w-full w-full sm:w-1/2 xl:w-fit flex flex-col sm:flex-row sm:px-6 py-6 sm:py-12 sm:space-x-10 sm:border-r-0 border-[#DDDDDD]">
            <div className="mb-6 sm:hidden xl:block sm:mb-0">
              <a href="#">
                <img src={logo} className="w-24 -mt-2" alt="Nefa Logo" />
              </a>
            </div>
            <ul className="space-y-4">
              <NavLink name="Explore" url="#" />
              <NavLink name="Copilot" url="#" />
              <NavLink name="Mock" url="#" />
              <NavLink name="Practice" url="#" />
              <NavLink name="Communication" url="#" />
            </ul>
          </div>
          <div className="md:w-full lg:w-full lg:border-r w-full sm:w-1/2 xl:w-fit sm:px-16 py-6 sm:py-12 border-t sm:border-t xl:border-r border-[#DDDDDD]">
            <ul className="space-y-4">
              <NavLink name="Products" url="#" />
              <NavLink name="About Us" url="#" />
              <NavLink name="Careers" url="#" />
              <NavLink name="Blog" url="#" />
              <NavLink name="Security" url="#" />
            </ul>
          </div>
          <div className="md:w-full md:border-t lg:w-full w-full sm:w-1/2 xl:w-fit sm:px-16 py-6 sm:py-12 border-t sm:border-t-0 sm:border-r-0 border-[#DDDDDD]">
            <ul className="space-y-4">
              <NavLink name="Help Center" url="#" />
              <NavLink name="Contact Us" url="#" />
              <NavLink name="System Status" url="#" />
              <NavLink name="Area of Avaibility" url="#" />
              <NavLink name="Privacy Policy" url="#" />
            </ul>
          </div>
          <div className="md:w-full md:border-t lg:w-full sm:px-10 py-6 sm:py-12 w-full sm:w-1/2 xl:w-[22rem] space-y-4 sm:border-t border-[#DDDDDD]">
            <h5 className="text-sm font-medium text-[#666666] focus:outline-none focus:shadow-outline">
              Newsletter
            </h5>
            <p className="text-sm text-[#666666] focus:outline-none focus:shadow-outline">
              Never miss anything information when <br className="sm:hidden" />
              you're on the go
            </p>
            <div className="flex items-center space-x-2">
              <input
                type="text"
                className="w-full px-2 py-4 sm:py-3 rounded-lg sm:rounded-md text-sm focus:outline-none border border-[#AAAAAA] placeholder-[#888]"
                placeholder="Enter your email"
              />
              <Button className="px-8 rounded-md h-11">
                <ArrowRightIcon size="20" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 sm:py-4 text-center text-sm text-[#666666] hover:text-gray-900">
        &copy; Copyright 2024 DipOffer. All rights reserved
      </div>
    </footer>
  )
}

export default LandingFooter
