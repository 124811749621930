import type { PropsWithChildren } from 'react'
import React from 'react'

const LandingSection: React.FC<PropsWithChildren> = ({ children, ...rest }) => {
  return (
    <div className="relative grid max-w-screen-xl grid-cols-12 px-4 mx-auto overflow-hidden sm:px-8 gap-x-6">
      {children}
    </div>
  )
}

export default LandingSection
