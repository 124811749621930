import React from 'react'
import clsx from 'clsx'

import BlurFade from '../blur-fade'

import landingFeatArrow from '@/assets/landing/landing_feat_arrow.png'
import landingFeatBefore from '@/assets/landing/landing_step_before.png'
import landingFeatDuration from '@/assets/landing/landing_step_duration.png'
import landingFeatAfter from '@/assets/landing/landing_step_after.png'
import landingFeatSupport from '@/assets/landing/landing_step_support.png'

type Step = {
  title: string
  description: string
  image: string
  className: string
}

const LandingStep = ({ step, last }: { step: Step; last: boolean }) => {
  return (
    <div className="max-w-[280px] xl:max-w-[363px] space-y-6 sm:space-y-3 text-center">
      <div className="relative">
        <img src={step.image} className="max-w-[245px] mx-auto" alt="" />
        {last && (
          <img
            src={landingFeatArrow}
            className={clsx(
              'hidden lg:block absolute top-1/2 -translate-y-1/2',
              'w-[72px] xl:w-[104px]',
              '-right-[54px] xl:-right-[72px]',
              'z-10',
            )}
          />
        )}
      </div>
      <h3 className="text-xl font-semibold text-neutral-800">{step.title}</h3>
      <p className="text-sm leading-relaxed text-gray-700 h-[42px]">
        {step.description}
      </p>
    </div>
  )
}

const LandingFeatureStep = () => {
  const steps: Step[] = [
    {
      title: 'Before interview',
      description: 'Courses Practice Mock Interview',
      image: landingFeatBefore,
      className: '',
    },
    {
      title: 'During interview',
      description: 'Interview Copilot Realtime Transcription Coding Copilot',
      image: landingFeatDuration,
      className: '',
    },
    {
      title: 'After interview',
      description: 'Interview Recap Salary Negotiation Auto Follow Up',
      image: landingFeatAfter,
      className: '',
    },
    {
      title: 'Continuous interview',
      description: 'AI Coach Pair Programming Group Study',
      image: landingFeatSupport,
      className: '',
    },
  ]
  return (
    <section className="w-full py-12 pb-20 overflow-hidden shadow bg-trading-tools sm:mx-4 xl:mx-10 sm:rounded-2xl">
      <div className="flex flex-col items-center w-full">
        <BlurFade delay={0.25} duration={0.7} inView>
          <h2
            data-aos="flip-down"
            className="text-3xl font-semibold text-center sm:text-4xl"
          >
            DipOffer is <span className="text-[#226CFF]">all you need</span> for
            interviews.
          </h2>
        </BlurFade>
        <div className="container px-4 mx-auto xl:px-10">
          <div className="relative flex flex-col items-center justify-between w-full px-4 mt-16 space-y-12 lg:flex-row lg:space-y-0 xl:px-10 sm:mt-8">
            {steps.map((step, index) => (
              <BlurFade
                key={index}
                delay={0.25 + index * 0.1}
                duration={0.7}
                inView
              >
                <LandingStep step={step} last={index < steps.length - 1} />
              </BlurFade>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingFeatureStep
